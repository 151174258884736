import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic, TweenMax, Power1 } from '../../utils/animationUtils';
import { useApplicationUrl } from '../../utils/customHooks';

function Social({ addAnimation }) {
    const applicationUrl = useApplicationUrl();

    useEffect(() => {
        var socialscene = new ScrollMagic.Scene({
            triggerElement: "#social", 
            duration: 400,
            offset: 0
        })
        .setTween([
            TweenMax.from($('.main__schedule'), 1, {
                transform: 'translateY(-40px) scale(.8)',
                ease: Power1.easeOut
            }),
            TweenMax.to($('.social_1'), .3, {
                opacity: 0,
                transform: 'translate(60px, 30px) scale(.4)',
                delay: .3,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.social_2'), .3, {
                opacity: 0,
                transform: 'translate(60px, 0) scale(.4)',
                delay: .4,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.social_3'), .3, {
                opacity: 0,
                transform: 'translate(60px, -10px) scale(.4)',
                delay: .5,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.social_4'), .6, {
                opacity: 1,
                delay: .7,
                transform: 'translateY(0)'
            }),
            TweenMax.to($('.social_5'), .6, {
                opacity: 1,
                delay: .9,
                transform: 'translateY(0)'
            })
        ])
        addAnimation(socialscene);        
        
        socialscene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 7 + ') a').addClass('active');
        });      
    }, [])
    
    return (
        <section className="cblock" id="social">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Monitor your social profiles</h2>
                    <p className="cblock__intro">Track your performance across your social profiles (Facebook, Instagram, Twitter, YouTube, SoundCloud, and LoudUp). Monitor any changes in your number of followers and engagement, and view your most popular posts.</p>
                    <a href={`${applicationUrl}/signup`} class="btn btn-primary btn-margin">Start monitoring my social engagement</a>
                </div>
            </div>
            <div className="cblock__part2">  
                <div className="cblock__screen cblock__screen--social">
                    <img className="social_1" src={require('../../images/facebook.png')} alt="Facebook"/>
                    <img className="social_2" src={require('../../images/instagram.png')} alt="Instagram"/>
                    <img className="social_3" src={require('../../images/twitter.png')} alt="Twitter"/>                    
                    <img className="social_4" src={require('../../images/social-popular-posts.png')} alt="Popular Posts"/>
                    <img className="social_5" src={require('../../images/social-latest-posts.png')} alt="Latest Posts"/>                    
                    <img className="main__schedule main__img img-shadow" src={require('../../images/social.png')} alt="Mike App helps you monitor your social profiles"/>
                </div>
            </div>
        </section>
    );
}

Social.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Social;