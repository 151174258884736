import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic, TweenMax, Power1 } from '../../utils/animationUtils';

function Finances({ addAnimation }) {
    useEffect(() => {
        const moneyscene = new ScrollMagic.Scene({
            triggerElement: "#money", 
            duration: 400,
            offset: -100
        })
        .setTween([
            TweenMax.from($('.main__money'), 1, {
                transform: 'translateY(-40px) scale(.8)',
                ease: Power1.easeOut
            }),
            TweenMax.to($('.money_1'), 1.2, {
                opacity: 0,
                delay: .1,
                left: '45%',
                top: '37%',
                ease: Power1.easeIn
            }),
            TweenMax.to($('.money_2'), 1.2, {
                opacity: 0,
                delay: .2,
                left: '45%',
                top: '37%',
                ease: Power1.easeIn
            }),
            TweenMax.to($('.money_3'), 1.2, {
                opacity: 0,
                delay: .3,
                left: '45%',
                top: '37%',
                ease: Power1.easeIn
            }),
            TweenMax.to($('.money_4'), 1.2, {
                opacity: 0,
                delay: .4,
                left: '45%',
                top: '37%',
                ease: Power1.easeIn
            }),
            TweenMax.to($('.money_0'), 1, {
                opacity: 1,
                delay: 1.2
            })
        ]);
        addAnimation(moneyscene);
        
        moneyscene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 4 + ') a').addClass('active');
        });
    }, [])
  
    return (
            <section className="cblock cblock--invert" id="money">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Manage Your Money</h2>
                    <p className="cblock__intro">Track your income and expenses with a beautiful chart to help you clearly visualise profits and losses. Set your budgets for both income and expenses and track your progress against your budgets to stay on track.</p>
                 </div>
            </div>

            <div className="cblock__part2">
                <div className="cblock__screen cblock__screen--money">
                    <img className="money_1" src={require('../../images/screen-finances-add1.png')} alt="Finances 1"/>
                    <img className="money_2" src={require('../../images/screen-finances-add2.png')} alt="Finances 2"/>
                    <img className="money_3" src={require('../../images/screen-finances-add3.png')} alt="Finances 3"/>
                    <img className="money_4" src={require('../../images/screen-finances-add4.png')} alt="Finances 4"/>
                    <img className="money_0" src={require('../../images/finances-web-chart.png')} alt="Finances Chart"/>
                    <img className="main__money  main__img img-shadow" src={require('../../images/screen-web-finances.png')} alt="Mike App helps with managing finances"/>
                </div>
            </div>
        </section>
    );
}

Finances.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Finances;