import React, { useRef } from "react";
import SEO from "../components/seo";
import SideNav from "../components/home/SideNav";
import Intro from "../components/home/Intro";
import Pricing from "../components/home/Pricing";
import Promo from "../components/home/Promo";
import Events from "../components/home/Events";
import Finances from "../components/home/Finances";
import Goals from "../components/home/Goals";
import Tasks from "../components/home/Tasks";
import Vault from "../components/home/Vault";
import Social from "../components/home/Social";
import Inspiration from "../components/home/Inspiration";
import { ScrollMagic } from '../utils/animationUtils';

export default function Home({ data, location }) {
  let animcontroller = useRef(null);

  const addAnimation = (obj) => {
    if(!animcontroller.current) {
      animcontroller.current = new ScrollMagic.Controller();
    }
    obj.addTo(animcontroller.current);
  }

  return (
    <React.Fragment>
      <SEO url={location.pathname} />
      <div>
        <SideNav location={location} />
        <Promo addAnimation={addAnimation} />
        <div className="content-blocks">
            <Intro addAnimation={addAnimation} />
            <Goals addAnimation={addAnimation} />
            <Tasks addAnimation={addAnimation} />
            <Finances addAnimation={addAnimation} />
            <Events addAnimation={addAnimation} />
            <Vault addAnimation={addAnimation} />
            <Social addAnimation={addAnimation} />
            <Inspiration addAnimation={addAnimation} />
            <Pricing addAnimation={addAnimation} />                        
        </div>  
      </div>
    </React.Fragment>
  )
}

