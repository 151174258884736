import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic, TweenMax, Power1 } from '../../utils/animationUtils';

function Vault({ addAnimation }) {
    useEffect(() => {
        const vaultscene = new ScrollMagic.Scene(
        {
            triggerElement: "#vault", 
            duration: 400,
            offset: 0
        })
        .setTween([
            TweenMax.from($('.main__vault'), 1, 
            {
                transform: 'translateY(-40px) scale(.8)',
                ease: Power1.easeOut
            }),
            TweenMax.to($('.vault_1'), .3, 
            {
                opacity: 0,
                transform: 'translateY(-100px) scale(.4)',
                delay: .1,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.vault_2'), .3, 
            {
                opacity: 0,
                transform: 'translateY(-100px) scale(.4)',
                delay: .2,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.vault_3'), .3, 
            {
                opacity: 0,
                transform: 'translateY(-100px) scale(.4)',
                delay: .3,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.vault_4'), .6, 
            {
                opacity: 1,
                delay: .5,
                transform: 'translateY(0)'
            })
        ]);
        addAnimation(vaultscene); 

        vaultscene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 6 + ') a').addClass('active');
        });
    }, [])      

    return (
        <section className="cblock cblock--invert" id="vault">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Capture your ideas</h2>
                    <p className="cblock__intro">Store notes, lyric ideas, that melody that just popped into your head, set lists, images, and PDF documents</p>
                </div>
            </div>

            <div className="cblock__part2">
                <div className="cblock__screen cblock__screen--vault">
                    <img className="vault_1" src={require('../../images/vault-web1.png')} alt="Vault 1"/>
                    <img className="vault_2" src={require('../../images/vault-web2.png')} alt="Vault 2"/>
                    <img className="vault_3" src={require('../../images/vault-web3.png')} alt="Vault 3"/>          
                    <img className="vault_4" src={require('../../images/vault-web4.png')} alt="Vault Items Added"/>
                    <img className="main__vault main__img img-shadow" src={require('../../images/screen-web-vault.png')} alt="Mike App helps you capture your ideas"/>
                </div>
            </div>
        </section>
    );
}

Vault.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Vault;
