import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic, TweenMax, Power1 } from '../../utils/animationUtils';
import { useApplicationUrl } from '../../utils/customHooks';

function Events({ addAnimation }) {
    const applicationUrl = useApplicationUrl();
    
    useEffect(() => {
        //-- Schedule
        const schedulescene = new ScrollMagic.Scene({
            triggerElement: "#schedule", 
            duration: 400,
            offset: 0
        })
        .setTween([
            TweenMax.from($('.main__schedule'), 1, {
                transform: 'translateY(-40px) scale(.8)',
                ease: Power1.easeOut
            }),
            TweenMax.to($('.schedule_1'), .3, {
                opacity: 0,
                transform: 'translate(60px, 30px) scale(.4)',
                delay: .3,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.schedule_2'), .3, {
                opacity: 0,
                transform: 'translate(60px 0) scale(.4)',
                delay: .4,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.schedule_3'), .3, {
                opacity: 0,
                transform: 'translate(60px -10px) scale(.4)',
                delay: .5,
                ease: Power1.easeIn
            }),
            TweenMax.to($('.schedule_4'), .6, {
                opacity: 1,
                delay: .7,
                transform: 'translateY(0)'
            }),
            TweenMax.to($('.schedule_5'), .6, {
                opacity: 1,
                delay: .9,
                transform: 'translateY(0)'
            })
        ]);
        addAnimation(schedulescene); 

        schedulescene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 5 + ') a').addClass('active');
        });
    }, []) 

    return (
        <section className="cblock" id="schedule">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Manage Your Schedule</h2>
                    <p className="cblock__intro">Capture your upcoming events, from gigs to rehearsals and meetings. Are you in a band? Quickly and conveniently manage the attendance of your band members for each event.</p>
                    <a href={`${applicationUrl}/signup`} class="btn btn-primary btn-margin">Start organising my schedule</a>
                </div>
            </div>

            <div className="cblock__part2">
                <div className="cblock__screen cblock__screen--schedule">
                    <img className="schedule_1" src={require('../../images/screen-schedule-add1.png')} alt="Schedule 1"/>
                    <img className="schedule_2" src={require('../../images/screen-schedule-add2.png')} alt="Schedule 2"/>
                    <img className="schedule_3" src={require('../../images/screen-schedule-add3.png')} alt="Schedule 3"/>          
                    <img className="schedule_4" src={require('../../images/schedule-web1.png')} alt="Schedule Added"/>
                    <img className="schedule_5" src={require('../../images/schedule-web2.png')} alt="Schedule Added"/>
                    <img className="main__schedule main__img img-shadow" src={require('../../images/screen-web-schedule.png')} alt="Mike App helps you manage your schedule"/>
                </div>
            </div>
        </section>
    );
}

Events.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Events;
