import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic } from '../../utils/animationUtils';

function Intro({ addAnimation }) {
    useEffect(() => {
        if($('#intro').length > 0) {
            const introscene = new ScrollMagic.Scene({
                triggerElement: "#intro", 
                duration: 400
            });
            addAnimation(introscene);
        
            introscene.on("enter", function (event) {
                $('.sidenav li a').removeClass('active');
                $('.sidenav li:eq(' + 1 + ') a').addClass('active');
            });
        }      
    }, []);
    
    return (
        <section className="cblock cblock--full cblock--intro" id="intro">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <p className="cblock__intro cblock__intro--larger">Meet Mike, your new band manager! From goal setting to managing your finances, projects, and events, Mike will keep you updated, motivated and in control of your own destiny, leaving you free to create amazing music.</p>
                    <h3>Some of the Mike app features:</h3>
                </div>
            </div>
        </section> 
    );
}

Intro.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Intro;
