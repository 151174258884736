import React, { useEffect } from 'react';
import $ from 'jquery';

function SideNav({ location }) {
    useEffect(() => {
        $('.smooth').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                let target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                const elementoffset = 0;
                
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - elementoffset
                    }, 1000);
                    return false;
                }
            }
        });
    }, []);

    return (
        <div className="sidenav">
            <ul className="list-unstyled">
                <li><a href="#promo" className="smooth active"><span className="sidenav__bar"/><span className="sidenav__label">Page Top</span></a></li>
                <li><a href="#intro" className="smooth"><span className="sidenav__bar" /><span className="sidenav__label">Intro</span></a></li>
                <li><a href="#goals" className="smooth"><span className="sidenav__bar" /><span className="sidenav__label">Achieve Your Goals</span></a></li>
                <li><a href="#getdone" className="smooth"><span className="sidenav__bar" /><span className="sidenav__label">Get Things Done</span></a></li>
                <li><a href="#money" className="smooth"><span className="sidenav__bar" /><span className="sidenav__label">Manage Your Money</span></a></li>
                <li><a href="#schedule" className="smooth"><span className="sidenav__bar" /><span className="sidenav__label">Manage Your Schedule</span></a></li>
                <li><a href="#vault" className="smooth"><span className="sidenav__bar"></span><span className="sidenav__label">Capture Your Ideas</span></a></li>
                <li><a href="#social" className="smooth"><span className="sidenav__bar"></span><span className="sidenav__label">Monitor Your Social Profiles</span></a></li>
                <li><a href="#inspired" className="smooth"><span className="sidenav__bar" /><span className="sidenav__label">Get Inspired</span></a></li>
                <li><a href="#pricing" className="smooth"><span className="sidenav__bar" /><span className="sidenav__label">Pricing</span></a></li>
            </ul>
        </div>
    );
}

export default SideNav;