import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic } from '../../utils/animationUtils';
import { useApplicationUrl } from '../../utils/customHooks.js';

function Promo({ addAnimation }) {
    const applicationUrl = useApplicationUrl();

    useEffect(() => {
        if($('#promo').length > 0) {
            const promoscene = new ScrollMagic.Scene({
                triggerElement: "#promo", 
                duration: 400
            });
            addAnimation(promoscene);
        
            promoscene.on("enter", function (event) {
                $('.sidenav li a').removeClass('active');
                $('.sidenav li:eq(' + 0 + ') a').addClass('active');
            });
        }
    }, []);

    return (
        <section className="promo" id="promo">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-sm-6 col-md-push-5 col-sm-push-6">  
                        <div className="promo__hero">
                            <div>
                                <img className="img-shadow" src={require('../../images/screen-web-home.png')} alt="Mike App Screenshots" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-6 col-md-pull-7 col-sm-pull-6">
                        <div className="promo__msg">
                            <h1>Take charge of your music career...</h1>
                            <p className="promo__intro">with a little help from Mike</p>
                            <p className="about-mike">Mike is an application that will give you the power to take charge of the business aspects of your music career.</p>
                            <ul className="list-unstyled list-checks">
                                <li>Set your goals and track your progress</li>
                                <li>Organise your workload and increase your productivity</li>
                                <li>Manage your budget and spending</li>
                                <li>Keep track of your upcoming events</li>
                            </ul>
                            <div className="ctablock">
                                <span className="ctablock__btns">
                                    <a href={`${applicationUrl}/signup`} className="btn btn-primary">Start a Free Trial</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    );
}

Promo.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Promo;