import urls from '../constants/urls.js';

class PricingService {
    
    static getPricingPlans () {        
        return fetch(`${urls.API_URL}/PricingPlans`)
            .then(response => {
                if(response.ok) {
                    return response.json();
                }
                else {
                    throw new Error('Network response was not ok');
                }
            })
            .then(data => {
                return Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to fetch pricing plans');
                throw new Error(error.message);
            });

    }
}
export default PricingService;