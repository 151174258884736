import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic } from '../../utils/animationUtils';

function Inspiration({ addAnimation }) {
    useEffect(() => {
        //-- Inspiration quotes slider
        if ($('.inspiration-slider', $('#inspired')).length !== 0) {
            //debugger;
            //const slickk = require('slick');
            $('.inspiration-slider', $('#inspired')).slick({
                dots: false,
                arrows: false,
                speed: 1000,
                autoplaySpeed: 4000,
                autoplay: true,
                slidesToShow: 1,
                fade: true,
                cssEase: 'linear'
            });
        }
        const inspirescene = new ScrollMagic.Scene({
            triggerElement: "#inspired", 
            duration: 400
        });
        addAnimation(inspirescene); 
    
        inspirescene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 7 + ') a').addClass('active');
        });
    }, [])
    
    return (
        <section className="cblock cblock--invert" id="inspired">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Get Inspired</h2>
                    <p className="cblock__intro">Get motivational quotes for those days when you need that extra push.</p>
                </div>
            </div>
            <div className="cblock__screen cblock__screen--schedule">
                <div className="inspiration-slider">
                    <div>
                        <img src={require('../../images/getinspired-hero-alt.jpg')} alt="It always seems Impossible until it's done - BB King"/>
                    </div>
                    <div>
                        <img src={require('../../images/getinspired-hero2-alt.jpg')} alt="Hard work beats talent when talent fails to work hard - Kevin Durant"/>
                    </div>
                    <div>
                        <img src={require('../../images/getinspired-hero3-alt.jpg')} alt="A good plan violently executed now is better than a perfect plan executed next week - George Patton"/>
                    </div>
                </div>        
            </div>
        </section> 
    );
}

Inspiration.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Inspiration;


