import urls from '../constants/urls.js';

class CurrencyService {
    static getCurrency ()
    {        
        return fetch(urls.API_URL + '/Currencies/Me')
            .then(response => {
                if(response.ok) {
                    return response.json();
                }
                else {
                    throw new Error('Network response was not ok');
                }
            })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                console.log('Unable to fetch currency');
                throw new Error('Unable to fetch currency');
            });
    }
}
export default CurrencyService;