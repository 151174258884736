import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic, TweenMax } from '../../utils/animationUtils';

function Goals({ addAnimation }) {
    useEffect(() => {
        const achievescene = new ScrollMagic.Scene({
            triggerElement: "#goals", 
            duration: 400,
            offset: 0
        })
        .setTween([
            TweenMax.from($('.main__goal'), 1, {
                transform: 'translateY(-40px) scale(.9)',
                opacity: 0
            }),
            TweenMax.from($('.goals_1'), 1, {
                transform: 'translateY(-40px) scale(.5)',
                opacity: 0,
                delay: .1
            }),
            TweenMax.from($('.goals_2'), 1, {
                transform: 'translateY(-40px) scale(.5)',
                opacity: 0,
                delay: .3
            }),
            TweenMax.from($('.goals_3'), 1, {
                transform: 'translateY(-40px) scale(.5)',
                opacity: 0,
                delay: .5
            })
        ]);
        addAnimation(achievescene);

        achievescene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 2 + ') a').addClass('active');
        });
    }, [])
    
    return (
        <section className="cblock cblock--invert" id="goals">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Achieve Your Goals</h2>
                    <p className="cblock__intro">From Facebook likes to Twitter Followers, Profit, Gigs, and more, set your goals and visualise your progress to stay motivated and on track until you have achieved your target.</p>
                </div>
            </div>
            <div className="cblock__part2">
                <div className="cblock__screen cblock__screen--goals">
                    <img className="goals_1" src={require('../../images/screen-goals-add1.png')} alt="Twitter"/>
                    <img className="goals_2" src={require('../../images/screen-goals-add2.png')} alt="Likes"/>
                    <img className="goals_3" src={require('../../images/screen-goals-add3.png')} alt="Email"/>
                    <img className="main__goal main__img img-shadow" src={require('../../images/screen-web-goals.png')} alt="Mike App helps track goals"/>
                </div>
            </div>
        </section> 
    );
}

Goals.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Goals;