import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic, TweenMax, Power1, Power2 } from '../../utils/animationUtils';
import { useApplicationUrl } from '../../utils/customHooks.js';

function Tasks({ addAnimation }) {
    const applicationUrl = useApplicationUrl();

    useEffect(() =>  {
        const getdonescene = new ScrollMagic.Scene(
        {
            triggerElement: "#getdone", 
            duration: 400,
            offset: -100
        })
        .setTween([
            TweenMax.from($('.main__task'), 1, 
            {
                transform: 'translateY(-40px) scale(.8)',
                ease: Power2.easeOut
            }),
            TweenMax.to($('.tasks_1'), 1, 
            {
                opacity: 1,
                delay: 0,
                width: '79.5%',
                left: '10.5%',
                top: '28.6%',
                ease: Power1.easeInOut
            }),
            TweenMax.to($('.tasks_2'), 1, 
            {
                opacity: 1,
                delay: .3,
                width: '79.5%',
                left: '10.5%',
                top: '35%',
                ease: Power1.easeInOut
            }),
            TweenMax.to($('.tasks_3'), 1, 
            {
                opacity: 1,
                delay: .3,
                width: '79.5%',
                left: '10.5%',
                top: '41.2%',
                ease: Power1.easeInOut
            }),
            TweenMax.to($('.tasks_4'), 1, 
            {
                opacity: 1,
                delay: .4,
                width: '79.5%',
                left: '10.5%',
                top: '53%',
                ease: Power1.easeInOut
            }),
            TweenMax.to($('.tasks_5'), 1, 
            {
                opacity: 1,
                delay: .5,
                width: '79.5%',
                left: '10.5%',
                top: '60%',
                ease: Power1.easeInOut
            })
        ]);
        addAnimation(getdonescene);

        getdonescene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 3 + ') a').addClass('active');
        }); 
    }, [])

    return (
        <section className="cblock" id="getdone">   
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Get Things Done</h2>
                    <p className="cblock__intro">Get productive by breaking down your projects into specific actionable tasks with due dates, and check them off when you're done.</p>
                    <a href={`${applicationUrl}/signup`} class="btn btn-primary btn-margin">Start getting things done</a>
                </div>
            </div>

            <div className="cblock__part2">
                <div className="cblock__screen cblock__screen--tasks">
                    <img className="tasks_1" src={require('../../images/tasks-web1.png')} alt="Task 1"/>
                    <img className="tasks_2" src={require('../../images/tasks-web2.png')} alt="Task 2"/>
                    <img className="tasks_3" src={require('../../images/tasks-web3.png')} alt="Task 3"/>
                    <img className="tasks_4" src={require('../../images/tasks-web4.png')} alt="Task 4"/>
                    <img className="tasks_5" src={require('../../images/tasks-web5.png')} alt="Task 5"/>
                    <img className="main__task main__img img-shadow" src={require('../../images/screen-web-tasks.png')} alt="Mike App enables you to create projects and tasks"/>
                </div>
            </div>
        </section>
    );
}

Tasks.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Tasks;