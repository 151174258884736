import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ScrollMagic, TweenMax, Power1 } from '../../utils/animationUtils';
import { useStaticQuery, graphql } from "gatsby";
import CurrencyService from '../../services/currencyService';
import PricingService from '../../services/pricingService';
import { useApplicationUrl } from '../../utils/customHooks.js';

function Pricing({ addAnimation }) {
    const data = useStaticQuery(
        graphql`
            query {
                currency {
                    Code
                    Description
                    ID
                    Symbol
                }
                allPricingPlans {
                    nodes {
                        ArtistType
                        Frequency
                        ID
                        Prices {
                            USD
                        }
                    }
                }
            }
        `
      )

    const applicationUrl = useApplicationUrl()
    const [artistType, setArtistType] = useState('Individual');
    const [currency, setCurrency] = useState(data.currency);
    const [pricingPlans, setPricingPlans] = useState(data.allPricingPlans.nodes)

    const getPricingPlan = (frequency) => {
        const currencyCode = currency ? currency.Code : 'USD';
        const pricingPlan = pricingPlans.find(p => p.ArtistType === artistType && p.Frequency === frequency).Prices[currencyCode];
        return pricingPlan;
    }
    let pricingPlan = {
        Monthly: 0,
        Annual: 0
    };
    if(pricingPlans && pricingPlans.length > 0) {
        pricingPlan.Monthly = getPricingPlan('Monthly');
        pricingPlan.Annual = getPricingPlan('Annual'); 
    }

    useEffect(() => {
        const pricingscene = new ScrollMagic.Scene({
            triggerElement: "#pricing", 
            duration: 400
        })
        .setTween([
            TweenMax.from($('.pricing'), 1, {
                transform: 'translateY(-40px) scale(.8)',
                opacity: 0,
                ease: Power1.easeOut
            })
        ]);
        addAnimation(pricingscene); 

        pricingscene.on("enter", function (event) {
            $('.sidenav li a').removeClass('active');
            $('.sidenav li:eq(' + 8 + ') a').addClass('active');
        });

        //get the currency again on mount, if not, static site generation means we'll always load one currency for everyone
        CurrencyService.getCurrency()
            .then(currency => setCurrency(currency))
            .catch(error => console.log('Unable to load currency'));
        PricingService.getPricingPlans()
            .then(pricingPlans => setPricingPlans(pricingPlans))
            .catch(error => console.log('Unable to load pricing plans'));
    }, []);

    const getLoginsText = () => {
        const individual = artistType === 'Individual';
        if (individual) {
            return 'Single user login';
        }
        else {
            return 'Unlimited band members';
        }
    }

    const setIndividual = (e) => {
        e.preventDefault();
        setArtistType('Individual');
    }

    const setBand = (e) => {
        e.preventDefault();
        setArtistType('Band');
    }

    const getCurrencySymbol = () => {
        if(currency) {
            return currency.Symbol;
        }
        return '$';
    }

    const getPrice = (frequency) => {
        let price;
        if(pricingPlan) {
            if(frequency == 'Monthly'){
                price = pricingPlan.Monthly;
            }
            else {
                price = pricingPlan.Annual;
            }
        }
        if(price === 0) {
            return '-';
        }
        else {
            return price;
        }
    }

    const getAnnualSaving = () => {
        if(pricingPlan) {
            let saving = (pricingPlan.Monthly * 12) - pricingPlan.Annual;
            //round to 2 decimal places by converting to cents, rounding, then converting back
            saving = Math.round(saving * 100)/100;
            return saving;
        }
        else {
            return '-';
        }
    }  

    return (
        <section className="cblock cblock--full cblock--pricing" id="pricing">
            <div className="cblock__part1">
                <div className="cblock__msg">
                    <h2>Simple Pricing</h2>
                    <p className="cblock__intro cblock__intro--small">Manage your finances, projects, schedule, and goals. Choose a subscription plan to get started:</p>
                    <div className="pricing">
                        <div className="pricing__head">
                            <div className="btn-group">
                                <a href="#" className={'btn btn-primary' + ((artistType === 'Individual') ? ' active' : '')} onClick={setIndividual}>I am an individual</a>
                                <a href="#" className={'btn btn-primary' + ((artistType === 'Band') ? ' active' : '')} onClick={setBand}>We are a band</a>
                            </div>
                        </div>
                        <div className="pricing__body">
                            <div className="pricing__box">
                                <h4>Monthly</h4>
                                <p>- {getLoginsText()}</p>
                                <p>- Start with a free trial, followed by monthly payments</p>
                                <p className="pricing__price">{getCurrencySymbol() + getPrice('Monthly')}<span>/month</span></p>
                            </div>
                            <div className="pricing__box">
                                <h4>Yearly</h4>
                                <p>- {getLoginsText()}</p>
                                <p>- Start with a free trial, followed by annual payments</p>
                                <div className="pricing__badge">
                                    <p>Save <br/>{getCurrencySymbol() + getAnnualSaving()} <br/><span>/year</span></p>
                                </div>
                                <p className="pricing__price">{getCurrencySymbol() + getPrice('Annual')}<span>/year</span></p>
                            </div>
                        </div>
                        <div className="pricing__foot">
                            <div className="ctablock">
                                <p>Start 1 month free trial</p>
                                <a href={`${applicationUrl}/signup`} className="btn btn-primary">Start a Free Trial</a>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    );
}

Pricing.propTypes = {
    addAnimation: PropTypes.func.isRequired
};

export default Pricing;

/*

function mapStateToProps(state, ownProps) {

    function getPricingPlan(pricingPlans, currencyCode, artistType, frequency) {
    const pricingPlan = pricingPlans.find(p => p.ArtistType === artistType && p.Frequency === frequency).Prices[currencyCode];
    return pricingPlan;
}
    let pricingPlan = {
        Monthly: 0,
        Annual: 0
    };
    if(state.pricingPlans && state.pricingPlans.length > 0) {
        const currencyCode = state.currency ? state.currency.Code : 'USD'
        pricingPlan.Monthly = getPricingPlan(state.pricingPlans, currencyCode, ownProps.artistType, 'Monthly');
        pricingPlan.Annual = getPricingPlan(state.pricingPlans, currencyCode, ownProps.artistType, 'Annual'); 
    }
    return { 
        currency: state.currency,
        pricingPlan: pricingPlan
    };
}
*/


